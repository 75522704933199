<template>
    <section class="look">
      <div class="container">
        <div class="look__title">
          <h1>{{ $t('cases_page.tnmk.title') }}</h1>
        </div>
        <p class="look__text ">{{ $t('cases_page.tnmk.desc') }}</p>
      </div>
    </section>
  
    <section class="presentation">
      <div class="container">
        <picture>
          <source srcset="../assets/cases/tnmk/cover_tnmk.webp" type="image/webp">
          <img class="presentation__image" src="../assets/cases/tnmk/cover_tnmk.png" alt="" loading="lazy" />
        </picture>
      </div>
    </section>
  
    <section class="about">
      <div class="container">
        <div class="line-wrapper">
          <div class="about__inner">
            <div class="about__tech">
              <div class="about__tech-item">
                <h3 class="about__tech-desc fonts__h3">
                  {{ $t('cases_page.d4u.category') }}
                </h3>
                <span>{{ $t('cases_page.tnmk.category_list') }}</span>
              </div>
              <div class="about__tech-item">
                <h3 class="about__tech-desc fonts__h3">{{ $t('cases_page.tnmk.services') }}</h3>
                <span class="">{{ $t('cases_page.tnmk.services_list') }}</span>
              </div>
              <div class="about__tech-item">
                <h3 class="about__tech-desc fonts__h3">{{ $t('cases_page.tnmk.about_tech') }}</h3>
                <span>{{ $t('cases_page.tnmk.about_tech_list') }}</span>
              </div>
            </div>
            <div class="about__info">
              <div>
                <h2 class="about__info-title ">{{ $t('cases_page.tnmk.about_info') }}</h2>
              </div>
              <p v-for="(content, index) in $tm('cases_page.tnmk.about_info_desc')" :key="index">{{ $rt(content) }}</p>
             
              <ButtonWhite 
                 buttonText="cases_page.tnmk.project_link"
                 httpButtonLink="https://www.tnmk.com/"
                 class="button-w"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  
    <section class="goal">
      <div class="container">
        <div class="line-wrapper">
          <div class="goal__inner">
            <div class="goal__title">
              <h2>{{ $t('cases_page.tnmk.goal_title') }}</h2>
            </div>
            <div class="goal__body">
              <p v-for="(content, index) in $tm('cases_page.tnmk.goal_title_list')" :key="index">{{ $rt(content) }}</p>
            </div>
          </div>
          <div class="goal__img">
              <picture>
                <source srcset="../assets/cases/tnmk/goal.webp" type="image/webp">
                <img class="development__image" src="../assets/cases/tnmk/goal.png" alt="" loading="lazy" />
              </picture>
          </div>
        </div>
      </div>
    </section>
  
    <section class="development">
      <div class="container">
          <div class="development__inner">
            <div class="development__inner__col1">
              <h2 class="development__inner__title ">{{ $t('cases_page.tnmk.development_title') }}
              </h2>
            </div>
            <div class="development__inner__col2">
              <p v-for="(content, index) in $tm('cases_page.tnmk.development_desc')"
              :key="index">{{ $rt(content) }}</p>
            </div>
          </div>
          <div class="development__inner__col1">
            <p>{{ $t('cases_page.tnmk.development_chalenge_title') }}</p>
            <ul>
              <li v-for="(content, index) in $tm('cases_page.tnmk.development_chalenge_list')" :key="index">{{ $rt(content) }}</li>
            </ul>
          </div>
      </div>
      <div class="development__img">
        <div class="container">
          <picture>
            <source srcset="../assets/cases/tnmk/dev_proc.webp" type="image/webp">
            <img class="development__image" src="../assets/cases/tnmk/dev_proc.png" alt="" loading="lazy" />
          </picture>
        </div>
      </div>
    </section>
  
    <section class="development donations">
      <div class="container">
          <div class="development__inner">
            <div class="development__inner__col1">
              <h2 class="development__inner__title ">{{ $t('cases_page.tnmk.development_donations_title') }}
                <p v-for="(content, index) in $tm('cases_page.tnmk.development_donations_desc')" :key="index">{{ $rt(content) }}</p>
              </h2>
            </div>
          </div>
      </div>
      <CaseSlider 
        :sliderMobileImg="require('../assets/cases/tnmk/phone1.png')" 
        :sliderPcImg="require('../assets/cases/tnmk/Macbook.png')"
        :sliderMobileTwoImg="require('../assets/cases/tnmk/phone2.png')" 
      />
    </section>
 
    <section class="conclusion">
      <div class="container">
         <div class="conclusion__inner">
            <div class="conclusion__inner__col1">
              <h2 class="conclusion__title">{{ $t('cases_page.tnmk.conclusion_title') }}</h2>
              <p class="conclusion__inner__col1__sub_title" v-for="(content, index) in $tm('cases_page.tnmk.conclusion_desc')" :key="index" v-html="$rt(content)"></p>
            </div>
          </div>
      </div>
      <WhatWasDone 
          :leftImg="require('../assets/cases/tnmk/concl_left.png')"
          :rightImg="require('../assets/cases/tnmk/concl_right.png')"
        />
    </section>
  
    <RecentProjects2 />
    <section class="contact">
      <Contact />
    </section>
  
  </template>
  
  <script>
  
  import Contact from "../components/ContactsNew";
  import { computed, reactive } from "vue";
  import { useHead } from "@vueuse/head";
  import RecentProjects2 from "../components/RecentProjects2";
  import WhatWasDone from "../components/WhatWasDone.vue";
  import ButtonWhite from "../components/buttons/ButtonWhite";
  import CaseSlider from "../components/CaseSliders/Slider2.vue";
  export default {
    name: "TNMK",
    components: {
      ButtonWhite,
      CaseSlider,
      WhatWasDone,
      RecentProjects2,
      Contact,
    },
    setup() {
      const siteData = reactive({
        title: "Webflow-Powered Website For TNMK",
        description: "Webmil web-production shares a case study on how we designed a fast-loading site with clear products visualization for a leading printing company",
        keywords: "webflow software development",
        robots: "index"
      })
  
      useHead({
        title: computed(() => siteData.title),
        meta: [
          {
            name: `description`,
            content: computed(() => siteData.description)
          },
          {
            name: `keywords`,
            content: computed(() => siteData.keywords)
          },
          {
            name: `robots`,
            content: computed(() => siteData.robots)
          },
        ],
      })
    },
  }
  </script>
  
  <style lang="scss">
  @import "../base/styles/media";
  @import "../base/styles/fonts.scss";

  .button-w{
    display: block;
    margin-top: 30px;
    font-weight: 700;
  }
  .line-wrapper {
    padding-bottom: 96px;
    border-bottom: 1px solid #DADADA;
    @media(max-width: 575px) {
      padding-bottom: 48px;
    }
  }
  
  .contact {
    margin-top: 96px;
    overflow: hidden;
  }
  
  .look {
    margin-top: 154px;
    
    @media(max-width: 991px) {
      margin-top: 135px;
    }
  
    @media(max-width: 575px) {
      margin-top: 96px;
    }
    h1{
        max-width: 1146px;
    }
    &__text {
      max-width: 465px;
      margin-top: 30px;
  
      @media(max-width: 991px) {
        font-size: 18px;
        line-height: 30px;
        margin-top: 20px;
        max-width: 400px;
      }
  
      @media(max-width: 575px) {
        font-size: 14px;
        line-height: 24px;
        margin-top: 10px;
      }
    }
  }

  .presentation {
    position: relative;
    margin-top: 64px;
  
    .container {
      padding: 0 12px;
      @media(max-width: 575px) {
        height: 175px;
        overflow: hidden;
      }
    }
  
    @media(max-width: 991px) {
      margin-top: 48px;
    }
  
    @media(max-width: 575px) {
      margin-top: 45px;
      padding-top: 0;
    }
  
    &__image {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  
  .about {
    margin-top: 64px;
  
    .container {
      padding: 0 12px;
    }
  
    @media(max-width: 991px) {
      margin-top: 48px;
    }
  
    @media(max-width: 991px) {
      margin-top: 48px;
    }
  
    @media(max-width: 575px) {
      margin-top: 30px;
  
      .container {
        padding: 0 12px 0 12px;
      }
    }
  
    @media(min-width: 992px) {
      &__inner {
        display: flex;
        column-gap: 30px;
      }
    }
  
    &__tech {
      width: 100%;
      max-width: 633px;
  
      @media(min-width: 992px) {
        padding-top: 30px;
      }
  
      &-item {
        &:not(:first-child) {
          margin-top: 30px;
  
          @media(max-width: 991px) {
            margin-top: 30px;
          }
  
          @media(max-width: 575px) {
            margin-top: 0px;
          }
        }
  
        @media(max-width: 575px) {
          display: flex;
          align-items: center;
        }
      }
  
      &-desc {
        position: relative;
        @media(max-width: 575px) {
          position: relative;
          &:after {
            content: ':';
            position: absolute;
            top: 0;
            right: -5px;
            display: inline-block;
            margin-left: -3px;
          }
        }
  
        @media(max-width: 575px) {
          &:after {
            content: ':';
            position: absolute;
            bottom: 0;
            right: -5px;
            display: inline-block;
          }
        }
      }
  
      span {
        display: inline-block;
        color: #2E3139;
        @media (min-width: 576px) {
          margin-top: 10px;
        }
  
        @media (max-width: 575px) {
          margin-left: 10px;
        }
      }
    }
  
    &__info {
      width: 100%;
      @media(max-width: 991px) {
        margin-top: 80px;
      }
  
      @media(max-width: 575px) {
        margin-top: 30px;
      }
  
      &-title {
        max-width: 601px;
        color: #2E3139;
      }
  
      &-text {
        max-width: 502px;
        margin-top: 30px;
        @media(max-width: 991px) {
          margin-top: 20px;
        }
  
        @media(max-width: 575px) {
          margin-top: 10px;
        }
      }
  
      &-btn {
        margin-top: 30px;
        @media(max-width: 991px) {
          margin-top: 30px;
        }
  
        @media(max-width: 575px) {
          margin-top: 30px;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
  
  .goal {
    margin-top: 96px;
    .container {
      padding: 0 12px;
    }
  
    @media(max-width: 991px) {
      margin-top: 48px;
    }
  
    @media(max-width: 575px) {
      margin-top: 30px;
  
      .container {
        padding: 0 12px 0 12px;
      }
    }
    &__img{
      margin-top: 96px;
      @media(max-width: 575px) {
        margin-top: 30px;
      }
      img{
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__inner {
      display: flex;
      column-gap: 30px;
      @media(max-width: 575px) {
        flex-direction: column;
      }
    }
  
    &__body {
        width: 100%;
        max-width: 633px;
      ul{
        @extend .fonts__ul;
      }
    }
  
    &__title {
      width: 100%;
      max-width: 633px;
      h2{
        max-width: 296px;
      }
    }
  
    &__main {
      flex: 0 1 502px;
      padding-top: 20px;
  
      @media(max-width: 575px) {
        padding-top: 10px;
      }
  
      @media(min-width: 992px) {
        margin-left: 100px;
      }
  
      &-text {
        font-weight: normal;
        font-size: 18px;
        line-height: 28px;
        color: #2E3139;
  
        @media(max-width: 575px) {
          font-size: 14px;
          line-height: 24px;
        }
      }
  
      span {
        display: inline-block;
        font-weight: bold;
        font-size: 18px;
        line-height: 28px;
        color: #2E3139;
        margin-top: 30px;
  
        @media(max-width: 575px) {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }
  
  .development{
    margin-top: 96px;
    @media(max-width: 575px) {
        margin-top: 48px;
    }
    &__img{
      margin-top: 96px;
      background: #1F1F1F;
      padding: 64px 0;
      @media(max-width: 575px){
        margin-top: 48px;
      }
      img{
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__inner{
        display: flex;
        column-gap: 30px;
        @media(max-width: 575px) {
          flex-direction: column;
        }
        &__col1:nth-child(2){
          margin-top: 64px;
          @media(max-width: 575px) {
            margin-top: 30px;
          }
          p{
            margin-top: 0;
          }
          p:nth-child(1){
            font-weight: 700;
            margin-top: 0;
          }
          ul{
            @extend .fonts__ul;
            margin-top: 30px;
            @media(max-width: 575px) {
              margin-top: 10px;
            }
          }

        }
        &__col1{
            width: 100%;
            max-width: 633px;
        }
        &__col2{
            width: 100%;
            max-width: 633px;
            p:nth-child(1){
                margin-top: 0;
                @media(max-width: 575px) {
                  padding-top: 10px;
                }
            }
        }
    }
  }
  
  /////
  
  .conclusion {
    margin: 96px 0 64px 0;
    @media(max-width: 575px) {
      margin: 48px 0 0 0;
    }
    .done_img{
      background: #1F1F1F;
    }
    &__inner{
        display: flex;
        column-gap: 30px;
        @media(max-width: 575px) {
          flex-direction: column;
        }
      &__col1{
            width: 100%;
            max-width: 633px;
        }
        &__col2{
            width: 100%;
            max-width: 633px;
            p:nth-child(1) {
              margin-top: 0;
              font-weight: 700;
              @media(max-width: 575px) {
                margin-top: 10px;
              }
            }
            ul{
              @extend .fonts__ul;
            }
        }

    }
  }
  
  </style>
  