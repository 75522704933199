<template>
  <div class="tablet">
    <div class="container">
        <div class="tablet__body" v-if="!this.isPc">
          <div class="tablet__body__col1">
            <img :src="sliderPcImg" alt="" loading="lazy" />
          </div>
          <div class="tablet__body__col2">
            <img :src="sliderMobileImg" alt="" loading="lazy" />
          </div>
          <div class="tablet__body__col3">
            <img :src="sliderMobileTwoImg" alt="" loading="lazy" />
          </div>
        </div>   
        <div v-else class="tablet__body">
          <Swiper :slides-per-view="1.2" :space-between="50">
            <SwiperSlide>
              <div class="tablet__swiper-image">
                  <img :src="sliderMobileImg" alt="" loading="lazy" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="tablet__swiper-image">
                  <img  class="tablet__swiper-img" :src="sliderPcImg" alt="" loading="lazy" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="tablet__swiper-image">
                  <img :src="sliderMobileTwoImg" alt="" loading="lazy" />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
    </div>
  </div>
</template>

<script >
import {Swiper, SwiperSlide} from "swiper/vue/swiper-vue.js";
import 'swiper/swiper.min.css';

export default {
  name: "Slider2",
  components: {
    Swiper,
    SwiperSlide,
  },
  data:() =>  ({
    isMobile: false,
    isPc: window.matchMedia('(max-width: 991px)').matches,
  }),
  props: {
    sliderMobileImg:{
      type: String,
    },
    sliderPcImg:{
      type: String,
    },
    sliderMobileTwoImg:{
      type: String,
    }
  },
  methods: {
    setIsPc(){
      this.isPc = window.matchMedia('(max-width: 991px)').matches;
    }
  },
  mounted() {
    window.addEventListener("resize", this.setIsPc);
  },
  unmounted() {
    document.removeEventListener('resize', this.setIsPc);
  },

}
</script>

<style lang="scss" scoped>
@import "../../base/styles/global.css";

.tablet{
  margin-top: 96px;
  background-color: #1F1F1F;
  @media(max-width: 575px) {
    margin-top: 48px;
  }
  .container{
    padding: 0 12px;
  }

  &__body{
    padding: 64px 0;
    display: flex;
    justify-content: center;
    &__col1{
      width: 100%;
      max-width: 895px;
      @media(min-width: 991px) {
      padding-top: 70px;
      }

      img{
        width: 100%;
        object-fit: cover;
      }

    }
    &__col2{
      width: 100%;
      max-width: 181px;
      @media(min-width: 991px) {
        margin-left: -135px;
      }

      img{
        width: 100%;
        object-fit: cover;
      }
    }
    &__col3{
      width: 100%;
      max-width: 181px;
      @media(min-width: 991px) {
        margin-left: 15px;
        margin-top: 99px;
      }
      img{
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .swiper{
    width: 100%;
    height: 300px;
    .swiper-slide{
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media(min-width: 575px) {
      align-items: center;
      }
    }
    .swiper-slide:nth-child(1){
      @media(max-width: 575px) {
        align-items: center;
      }
    }

    img{
      max-height: 300px;
    }
  }
  &__swiper-img{
    width: 100%;
  }
}
</style>